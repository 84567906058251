import React from 'react';
import './App.css';
import Box from '@material-ui/core/Box';
import SignIn from './SignIn.js';
import ForgotPassword from './ForgotPassword.js';
import Register from './Register.js';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { relative } from 'path';

import ReadyStockLogoClear from './assets/RDSTKblkcopy.png';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      //backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    position: 'relative',
    top: '-50px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright © readystock {new Date().getFullYear()}
    </Typography>
  );
}

function App() {
  const classes = useStyles();

  return (
    <Router>
      <div>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={ReadyStockLogoClear} style={{
              "width": "300px"
            }} alt="readystock" />
            <Switch>
              <Route path="/ForgotPassword">
                <ForgotPassword classes={classes}></ForgotPassword>
              </Route>
              <Route path="/Register">
                <Register classes={classes}></Register>
              </Route>
              <Route path="/">
                <SignIn classes={classes}></SignIn>
              </Route>
            </Switch>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container >
      </div>
    </Router >
  );
}

export default App;
