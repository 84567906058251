import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField, List, ListItem, Card, CardContent, ButtonBase } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
//import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {
    Link
} from 'react-router-dom';
import config from './Config.js';
import { useCookies } from 'react-cookie';
import { hasValue } from './Util';
import MuiAlert from '@material-ui/lab/Alert';
import linq from 'linq';

const SignIn = ({ classes }) => {
    let [errors, setErrors] = useState([]);
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [token, setToken] = useState("");
    let [accounts, setAccounts] = useState([]);
    let [selectedAccountId, setSelectedAccountId] = useState(-1);
    let [, setCookies] = useCookies([config.AccountIdCookieKey, config.UserIdCookieKey]);

    let hasAccounts = (accounts !== null && accounts !== undefined && accounts.length > 0);

    console.log(config);

    let signOn = function (e) {
        e.preventDefault();

        if (!hasAccounts) {
            fetch(config.LoginApiBaseUrl + "/login", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": email,
                    "password": password
                }),
            })
                .then(x => {
                    return {
                        Success: x.ok,
                        data: x.json()
                    };
                })
                .then(x => {
                    console.log(x);
                    if (x.Success) {
                        x.data.then(y => {
                            console.log(y);

                            if (y.error !== null && y.error !== undefined) {
                                setErrors([y.error]);
                            }
                            else if (y.accounts.length === 1) {
                                setCookies(config.RTokenCookieKey, y.token, {
                                    path: '/',
                                    domain: config.CookieDomain
                                });

                                window.location = config.WebAppBaseUrl;
                            } else {
                                setToken(y.token);
                                setAccounts(y.accounts);
                            }
                        });
                    }
                    else {
                        x.data.then(y => setErrors([y.error]));
                    }
                })
                .catch(ex => {
                    console.log(ex);
                });
        } else {
            if (selectedAccountId <= 0) {
                setErrors(["Please select a valid account."]);
                return;
            }

            fetch(config.LoginApiBaseUrl + "/login/account/" + selectedAccountId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'R-Token': token
                },
            })
                .then(x => {
                    return {
                        Success: x.ok,
                        data: x.json()
                    };
                })
                .then(x => {
                    console.log(x);
                    if (x.Success) {
                        x.data.then(y => {
                            if (y.error !== null && y.error !== undefined) {
                                setErrors([y.error]);
                            }
                            else {
                                setCookies(config.RTokenCookieKey, y.token, {
                                    path: '/',
                                    domain: config.CookieDomain
                                });

                                window.location = config.WebAppBaseUrl;
                            }
                        });
                    }
                    else {
                        x.data.then(y => setErrors([y.error]));
                    }
                })
                .catch(ex => {
                    console.log(ex);
                });
        }
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <form className={classes.form} noValidate onSubmit={(e) => signOn(e)}>
            {errors.length > 0 &&
                <Alert severity="error">
                    <List>
                        {errors.map(error => {
                            return (
                                <ListItem>{error}</ListItem>
                            )
                        })}
                    </List>
                </Alert>
            }
            {!hasAccounts &&
                <div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.currentTarget.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/ForgotPassword" variant="body2">Forgot password?</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/Register" variant="body2">Don't have an account? Sign Up</Link>
                        </Grid>
                    </Grid>
                </div>
            }
            {hasAccounts &&
                <div>
                    <p>Please select an account:</p>
                    {linq.from(accounts).orderBy(x => x.name).toArray().map(account => (
                        <ButtonBase style={{ width: "100%", margin: "1em" }} type="submit" onClick={() => setSelectedAccountId(account.accountId)}>
                            <Card style={{ width: "100%" }}>
                                <CardContent>
                                    {account.name}
                                </CardContent>
                            </Card>
                        </ButtonBase>
                    ))}
                </div>
            }
        </form >
    );
};

export default SignIn;