import Development from './Config.Development.json';
import Staging from './Config.Staging.json';

let config = {

};

if (process.env.REACT_APP_ENVIRONMENT === 'Staging') {
    config = {
        ...Staging
    };
} else {
    config = {
        ...Development
    };
}

export default config;