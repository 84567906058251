import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { TextField, List, ListItem } from '@material-ui/core';
import {
    Link
} from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { getParams, hasValue } from './Util'
import config from './Config.js';
import { useCookies } from 'react-cookie';

//TODO Add a captcha

const Register = ({ classes }) => {
    let [errors, setErrors] = useState([]);
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [confirmPassword, setConfirmPassword] = useState("");
    let [token, setToken] = useState("");
    let [accountName, setAccountName] = useState("");
    let [, setCookies] = useCookies([config.AccountIdCookieKey, config.UserIdCookieKey]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    let params = getParams(window.location.href);
    useEffect(() => {
        if (params.useTestData === "true") {
            setFirstName("James");
            setLastName("Anderbard");
            setEmail("james.anderbard@readystock.io");
            setPassword("Test123");
            setConfirmPassword("Test123");

        }
    }, [params.useTestData]);

    let register = (e) => {
        e.preventDefault();
        let errors = [];

        if (token === undefined || token === "") {
            if (!hasValue(firstName))
                errors[errors.length] = "Invalid First Name.";

            if (!hasValue(lastName))
                errors[errors.length] = "Invalid Last Name.";

            if (!hasValue(email))
                errors[errors.length] = "Invalid Email.";

            if (!hasValue(password))
                errors[errors.length] = "Invalid Password";

            if (password !== confirmPassword)
                errors[errors.length] = "Passwords do not match.";

            setErrors(errors);

            fetch(config.LoginApiBaseUrl + "/register", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "email": email,
                    "firstName": firstName,
                    "lastName": lastName,
                    "password": password
                }),
            })
                .then(x => {
                    return {
                        Success: x.ok,
                        data: x.json()
                    };
                })
                .then(x => {
                    console.log(x);
                    if (x.Success) {
                        x.data.then(y => {
                            if (y.error !== null && y.error !== undefined) {
                                setErrors([y.error]);
                            }
                            else if (y.accounts === undefined) {
                                setToken(y.token);
                            } else {

                            }
                        });
                    }
                    else {
                        x.data.then(y => setErrors([y.error]));
                    }
                })
                .catch(ex => {
                    console.log(ex);
                });
        } else {
            if (!hasValue(accountName))
                errors[errors.length] = "Invalid Account Name.";

            fetch(config.LoginApiBaseUrl + "/register/account", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'R-Token': token
                },
                body: JSON.stringify({
                    "name": accountName
                }),
            })
                .then(x => {
                    return {
                        Success: x.ok,
                        data: x.json()
                    };
                })
                .then(x => {
                    console.log(x);
                    if (x.Success) {
                        x.data.then(y => {
                            if (y.error !== null && y.error !== undefined) {
                                setErrors([y.error]);
                            }
                            else {
                                setCookies(config.RTokenCookieKey, y.token, {
                                    path: '/',
                                    domain: config.CookieDomain
                                });

                                window.location = config.WebAppBaseUrl;
                            }
                        });
                    }
                    else {
                        x.data.then(y => setErrors([y.error]));
                    }
                })
                .catch(ex => {
                    console.log(ex);
                });
        }
    };

    return (
        <form className={classes.form} noValidate onSubmit={register}>
            <p><Link to="/">Back</Link></p>
            <h2>Register for a readystock account.</h2>
            <p>This will create a brand new readystock account. If you are looking to join an existing account, please have your readystock account admin send an invitation to your email instead.</p>
            {errors.length > 0 &&
                <Alert severity="error">
                    <List>
                        {errors.map(error => {
                            return (
                                <ListItem>{error}</ListItem>
                            )
                        })}
                    </List>
                </Alert>
            }
            {(token === undefined || token === "") &&
                <div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="firstName"
                        autoFocus
                        onChange={e => setFirstName(e.currentTarget.value)}
                        value={firstName}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lastName"
                        onChange={e => setLastName(e.currentTarget.value)}
                        value={lastName}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={e => setEmail(e.currentTarget.value)}
                        value={email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="password"
                        onChange={e => setPassword(e.currentTarget.value)}
                        value={password}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="confirm-password"
                        onChange={e => setConfirmPassword(e.currentTarget.value)}
                        value={confirmPassword}
                    />
                </div>
            }

            {token !== undefined && token !== "" &&
                <div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="accountName"
                        label="Account Name"
                        type="text"
                        id="accountName"
                        autoComplete="account-name"
                        onChange={e => setAccountName(e.currentTarget.value)}
                        value={accountName}
                    />
                </div>
            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Register
            </Button>
        </form>
    );
}

export default Register;