import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
    Link
} from 'react-router-dom';

const ForgotPasword = ({ classes }) => {

    return (
        <form className={classes.form} noValidate>
            <p><Link to="/">Back</Link></p>
            <h2>Forgot Password</h2>
            <p>Please enter an email address and we will send a temporary password for the user. Once used you will need to provide a new password.</p>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                Submit
                    </Button>
        </form>
    );
}

export default ForgotPasword;